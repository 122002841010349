import { RouteRecordRaw } from 'vue-router';
import Home from '@/views/home/Index.vue';
import Overview from '@/views/home/Overview/Index.vue';
import QuickGuides from '@/views/home/QuickGuides/Index.vue';
import About from '@/views/home/About/Index.vue';

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        redirect: { name: 'Overview' },
        children: [
            {
                path: '/oversigt/:path(.*)?',
                name: 'Overview',
                component: Overview,
            },
            {
                path: '/quick-guides/:quickGuideTitle?',
                name: 'QuickGuides',
                component: QuickGuides,
            },
            {
                path: '/om/:pageTitle?',
                name: 'About',
                component: About,
            },
        ],
    },
];
