import { defineStore } from 'pinia';
import { QuickGuide } from '@/types';
import FirebaseClient, { Collection } from './FirebaseClient';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

const useStore = defineStore('quickGuides', {
    state: () => {
        return {
            quickGuides: null as null | QuickGuide[],
            activeQuickGuide: null as null | QuickGuide,
        };
    },
    getters: {},
    actions: {
        async initSite() {
            this.getQuickGuides();
        },
        async getQuickGuides() {
            if (this.quickGuides === null) {
                const quickGuides = await FirebaseClient.getCollection<QuickGuide>(Collection.QuickGuides);
                this.quickGuides = quickGuides.sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                });
            }
        },
        async setActiveQuickGuideForRoute(pathParams: { quickGuideTitle: string }) {
            if (this.quickGuides === null) {
                await new Promise((resolve) => {
                    const unsubscribe = this.$subscribe((mutation, state) => {
                        if (state.quickGuides !== null) {
                            unsubscribe();
                            resolve(true);
                        }
                    });
                });
            }
            const resolvedQuickGuide = this.quickGuides!.find((currentQuickGuide) => URIEncodeItem(currentQuickGuide) === URIEncodeItem({ title: pathParams.quickGuideTitle }));
            if (resolvedQuickGuide) {
                this.activeQuickGuide = resolvedQuickGuide;
            }
        },
    },
});

export { useStore };
