
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore as useQuickGuidesStore } from '@/plugins/store/quickGuidesStore';
import { useStore as useOverviewStore } from '@/plugins/store/overviewStore';
import { Item, QuickGuide } from '@/types';
import OverviewNavigationItem from '@/views/home/Overview/navigation/OverviewNavigationItem.vue';
import useFootnotes from '@/util/composables/useFootnotes';

export default defineComponent({
    components: { OverviewNavigationItem },
    setup() {
        const { onUnmounted } = useFootnotes();
        return { onUnmounted };
    },
    data() {
        return {
            passedScrollThreshold: false,
            activeSection: null as null | string,
        };
    },
    mounted() {
        window.onscroll = () => {
            if (document.body.scrollTop > 99 || document.documentElement.scrollTop > 99) {
                this.passedScrollThreshold = true;
            } else {
                this.passedScrollThreshold = false;
            }
        };
    },
    watch: {
        activeQuickGuide: {
            immediate: true,
            handler(newValue: QuickGuide) {
                if (newValue) {
                    this.activeSection = newValue.sections[0]?.title;
                }
            },
        },
    },
    computed: {
        ...mapState(useQuickGuidesStore, ['activeQuickGuide']),
        ...mapState(useOverviewStore, ['items']),
        readAlso(): Item[] {
            const result = this.activeQuickGuide?.readAlso
                ?.map((currentReadAlso) => {
                    return this.items?.find((currentItem) => currentItem.id === currentReadAlso.id);
                })
                .filter((currentReadAlsoItem) => Boolean(currentReadAlsoItem));
            return result as Item[];
        },
    },
    methods: {
        getIdFromTitle(title: string) {
            return title.trim().replace(/ {2}/, '_').replace(/\W/g, '_');
        },
        onChange(e: any) {
            const sectionElement = document.getElementById(this.getIdFromTitle(e.currentTarget.value));
            if (sectionElement) {
                const headerOffset = 250;
                const elementPosition = sectionElement.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                window.scrollTo({
                    top: offsetPosition,
                });
            }
        },
    },
});
