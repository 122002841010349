import { createApp } from 'vue';
import { createPinia } from 'pinia';
import InstantSearch from 'vue-instantsearch/vue3/es';
import App from '@/App.vue';
import router from '@/plugins/router';
import '@/assets/css/index.css';
import * as firebase from '@/plugins/firebase';
import { useStore as useOverviewStore } from '@/plugins/store/overviewStore';
import { useStore as useAboutStore } from '@/plugins/store/aboutStore';
import { useStore as useQuickGuidesStore } from '@/plugins/store/quickGuidesStore';
import { useStore as useMainStore } from '@/plugins/store/mainStore';
import { initAlgolia } from '@/plugins/algolia';

const pinia = createPinia();

createApp(App).use(router).use(pinia).use(InstantSearch).mount('#app');

useOverviewStore().initSite();
useAboutStore().initSite();
useQuickGuidesStore().initSite();
useMainStore().initSite();
initAlgolia();
