
import { defineComponent } from 'vue';
import { mapState, mapWritableState } from 'pinia';
import { useStore } from '@/plugins/store/aboutStore';
import { Page } from '@/types';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

export default defineComponent({
    computed: {
        ...mapState(useStore, ['pages']),
        ...mapWritableState(useStore, ['activePage']),
    },
    methods: {
        getURIEncodedPageTitle(page: Page) {
            return URIEncodeItem(page);
        },
    },
    watch: {
        pages: {
            immediate: true,
            deep: true,
            handler(newValue: Page[]) {
                if (newValue && !this.activePage) {
                    this.$router.replace({ name: 'About', params: { pageTitle: this.getURIEncodedPageTitle(newValue[0]) } });
                }
            },
        },
        activePage: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue === null && this.pages) {
                    this.activePage = this.pages[0];
                }
            },
        },
    },
});
