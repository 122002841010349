import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/plugins/router/routes';
import { useStore as useOverviewStore } from '@/plugins/store/overviewStore';
import { useStore as useAboutStore } from '@/plugins/store/aboutStore';
import { useStore as useQuickGuidesStore } from '@/plugins/store/quickGuidesStore';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {
    const overviewStore = useOverviewStore();
    const aboutStore = useAboutStore();
    const quickGuidesStore = useQuickGuidesStore();

    aboutStore.activePage = null;
    quickGuidesStore.activeQuickGuide = null;

    if (to.params.path) {
        await overviewStore.setActiveSectionAndSiteForRoute(to.params as { path: string });
    } else {
        overviewStore.activeItem = null;
        overviewStore.activeSection = null;
    }
    if (to.params.pageTitle) {
        await aboutStore.setActivePageForRoute(to.params as { pageTitle: string });
    }
    if (to.params.quickGuideTitle) {
        await quickGuidesStore.setActiveQuickGuideForRoute(to.params as { quickGuideTitle: string });
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
});

export default router;
