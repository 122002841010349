import { useStore } from '@/plugins/store/overviewStore';
import { Item, Section } from '@/types';
import { waitForItems } from '@/util/helpers/waitForItems';

export default function useItemPaths() {
    const store = useStore();

    function getPathForItem(item: Item, includeSection: boolean) {
        if (store.items === null || store.sections === null) {
            return [];
        }
        const result: (Section | Item)[] = [];
        let reachedEnd = false;
        let currentItem = item;
        while (!reachedEnd) {
            result.push(currentItem);
            if (currentItem.parentItem && currentItem.parentItem.length) {
                currentItem = store.itemsAndSectionsDirectAccess.items[currentItem.parentItem[0].id];
            } else if (currentItem.parentSection && currentItem.parentSection.length) {
                if (includeSection) {
                    result.push(store.itemsAndSectionsDirectAccess.sections[currentItem.parentSection[0].id]);
                }
                reachedEnd = true;
            }
        }

        return result.reverse();
    }

    return { waitForItems, getPathForItem };
}
