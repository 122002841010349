import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-156f8495"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "lg:flex justify-between lg:space-x-2 space-y-4 lg:space-y-0 lg:min-h-11" }
const _hoisted_3 = { class: "flex lg:space-x-8 w-full overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverviewBreadcrump = _resolveComponent("OverviewBreadcrump")!
  const _component_AlgoliaSearch = _resolveComponent("AlgoliaSearch")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Content = _resolveComponent("Content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_OverviewBreadcrump),
      _createVNode(_component_AlgoliaSearch)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Navigation, {
        class: _normalizeClass([_ctx.activeItem ? 'navigation-inactive' : 'navigation-active', "lg:w-1/3 flex overview-section"])
      }, null, 8, ["class"]),
      _createVNode(_component_Content, {
        class: _normalizeClass([_ctx.activeItem ? 'content-active' : 'content-inactive', "lg:w-2/3 mb-auto overview-section"])
      }, null, 8, ["class"])
    ])
  ]))
}