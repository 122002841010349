import { useStore } from '@/plugins/store/overviewStore';

export async function waitForItems() {
    const store = useStore();
    if (store.items === null || store.sections === null) {
        await new Promise((resolve) => {
            const unsubscribe = store.$subscribe((mutation, state) => {
                if (state.items !== null && state.sections !== null) {
                    unsubscribe();
                    resolve(true);
                }
            });
        });
    }
}
