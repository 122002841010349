import { defineStore } from 'pinia';
import { SearchClient } from 'algoliasearch/lite';
import { Footnote, Page } from '@/types';
import FirebaseClient, { Collection } from './FirebaseClient';

const useStore = defineStore('main', {
    state: () => {
        return {
            searchClient: null as null | SearchClient,
            footnotes: null as null | { [key: string]: Footnote },
            activeFootnote: null as null | Footnote,
        };
    },
    actions: {
        async initSite() {
            this.getFootnotes();
        },
        async getFootnotes() {
            if (this.footnotes === null) {
                const footnotes = await FirebaseClient.getCollection<Footnote>(Collection.FootNotes);
                this.footnotes = footnotes.reduce((accum, current) => {
                    accum[current.id] = current;
                    return accum;
                }, {} as { [key: string]: Footnote });
            }
        },
    },
});

export { useStore };
