
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import Modal from '@/components/ui/Modal.vue';
import { useStore } from '@/plugins/store/mainStore';

export default defineComponent({
    components: { Modal },
    computed: {
        ...mapState(useStore, ['activeFootnote']),
        activeFootnoteContent(): string {
            if (!this.activeFootnote) {
                return '';
            }
            if (this.activeFootnote.content.match(/^www\.\S*$/)) {
                return `<a class="text-center text-blue-600 underline" href="https://${this.activeFootnote.content}" target="_blank">${this.activeFootnote.content}</a>`;
            }
            if (this.activeFootnote.content.match(/^https:\/\/\S*$/)) {
                return `<a class="text-center text-blue-600 underline" href="${this.activeFootnote.content}" target="_blank">${this.activeFootnote.content}</a>`;
            }
            return this.activeFootnote.content;
        },
    },
});
