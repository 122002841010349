
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';
import { Item } from '@/types';
import useItemPaths from '@/util/composables/useItemPaths';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

export default defineComponent({
    props: {
        item: {
            type: Object as () => Item,
            required: true,
        },
    },
    computed: {
        ...mapState(useStore, ['items', 'sections', 'activeItem']),
        path() {
            const { getPathForItem } = useItemPaths();
            return getPathForItem(this.$props.item, true)
                .map((currentItemOrSection) => URIEncodeItem(currentItemOrSection))
                .join('/');
        },
    },
});
