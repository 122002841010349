
import { defineComponent, ref, computed } from 'vue';
import useEscapable from '@/util/composables/useEscapable';

export default defineComponent({
    emits: ['close'],
    props: {
        size: {
            type: String,
            default: '',
        },
        padding: {
            type: String,
            default: 'p-6',
        },
        closeButton: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const isOpen = ref<boolean>(false);
        const close = () => {
            context.emit('close');
            isOpen.value = false;
        };
        const open = () => {
            isOpen.value = true;
        };

        const state = computed(() => {
            return { isOpen: isOpen.value, open, close };
        });

        const { onUnmounted } = useEscapable(close);
        return { onUnmounted, open, close, isOpen, state };
    },
});
