import algoliasearch from 'algoliasearch/lite';
import { useStore } from '@/plugins/store/mainStore';

function initAlgolia() {
    const searchClient = algoliasearch(process.env.VUE_APP_ALGOLIA_APPLICATION_ID || '', process.env.VUE_APP_ALGOLIA_SEARCH_KEY || '');
    const store = useStore();
    store.$state.searchClient = searchClient;
}

export { initAlgolia };
