import { onUnmounted } from 'vue';
import { useStore } from '@/plugins/store/mainStore';

export default function useFootnotes() {
    const store = useStore();
    const clickHandler = (e: MouseEvent) => {
        const target = e.target as HTMLElement | null;
        if (target?.classList.contains('footnote') && target.dataset.footnoteId && store.footnotes) {
            const footnote = store.footnotes[`${target.dataset.footnoteId}`];
            const modalInputElement: HTMLInputElement | null = document.querySelector('#footnote-modal-input');
            if (modalInputElement && footnote) {
                store.activeFootnote = footnote;
                modalInputElement.click();
            }
        }
    };

    document.addEventListener('click', clickHandler);

    onUnmounted(() => {
        document.removeEventListener('click', clickHandler);
    });

    return { onUnmounted };
}
