
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';
import { HierarchyItem, Item, Section } from '@/types';
import OverviewNavigationItem from '@/views/home/Overview/navigation/OverviewNavigationItem.vue';
import useItemPaths from '@/util/composables/useItemPaths';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

export default defineComponent({
    components: { OverviewNavigationItem },
    computed: {
        ...mapState(useStore, ['sectionAndItemsHierarchy', 'activeSection', 'activeItem']),
        flatHierarchyForActiveSection(): HierarchyItem[] | undefined {
            const section = this.sectionAndItemsHierarchy?.find((currentSection) => currentSection.id === this.activeSection?.id);
            return section?.items.flatMap((currentHierarchyItem) => this.getItemsFromHierarchyItem(currentHierarchyItem));
        },
    },
    methods: {
        getItemsFromHierarchyItem(item: HierarchyItem): HierarchyItem[] {
            return [
                item,
                ...item.items.flatMap((currentItem) => {
                    return this.getItemsFromHierarchyItem(currentItem);
                }),
            ];
        },
        getRouteForItem(item: HierarchyItem) {
            const { getPathForItem } = useItemPaths();
            return getPathForItem(item, true)
                .map((currentItemOrSection) => URIEncodeItem(currentItemOrSection))
                .join('/');
        },
        URIEncodeItem,
    },
});
