
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';
import { HierarchyItem } from '@/types';

export default defineComponent({
    computed: {
        ...mapState(useStore, ['activeSection', 'itemsSortedByParent', 'sectionAndItemsHierarchy']),
        flatHierarchyForActiveSection(): HierarchyItem[] | undefined {
            const section = this.sectionAndItemsHierarchy?.find((currentSection) => currentSection.id === this.activeSection?.id);
            return section?.items.flatMap((currentHierarchyItem) => this.getItemsFromHierarchyItem(currentHierarchyItem));
        },
    },
    methods: {
        getItemsFromHierarchyItem(item: HierarchyItem): HierarchyItem[] {
            return [
                item,
                ...item.items.flatMap((currentItem) => {
                    return this.getItemsFromHierarchyItem(currentItem);
                }),
            ];
        },
    },
});
