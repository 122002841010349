import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "my-auto" }
const _hoisted_3 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quickGuides, (quickGuide) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: quickGuide.id,
        to: {
                name: 'QuickGuides',
                params: { quickGuideTitle: _ctx.getURIEncodedQuickGuideTitle(quickGuide) },
            },
        class: _normalizeClass([[{ 'lg:shadow-inner-equal-sm selected-route': quickGuide.id === _ctx.activeQuickGuide?.id }, { 'shadow-md': quickGuide.id !== _ctx.activeQuickGuide?.id }], "bg-white rounded-md py-4 px-4 w-full text-left flex space-x-2 border-l-8 border-y border-r"]),
        style: _normalizeStyle([`border-left-color: ${quickGuide.color};`, `${quickGuide.id === _ctx.activeQuickGuide?.id ? `--tw-shadow-color: ${quickGuide.color}; --tw-shadow: var(--tw-shadow-colored);` : ''}`])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h3", null, _toDisplayString(quickGuide.title), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(quickGuide.subtitle), 1)
          ])
        ]),
        _: 2
      }, 1032, ["to", "class", "style"]))
    }), 128))
  ]))
}