import { collection as firestoreCollection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '@/plugins/firebase';

export enum Collection {
    FootNotes = 'footnotes',
    Items = 'items',
    Meta = 'meta',
    Pages = 'pages',
    QuickGuides = 'quickGuides',
    Sections = 'sections',
}

export default class FirebaseClient {
    public static async getCollection<T>(rootCollection: Collection, ...pathSegments: string[]): Promise<T[]> {
        const querySnapshot = await getDocs(firestoreCollection(db, rootCollection, ...pathSegments));
        return querySnapshot.docs.map((currentDoc) => {
            return { ...currentDoc.data(), id: currentDoc.id } as unknown as T;
        });
    }

    public static async getDocumentData<T>(collection: Collection, id: string) {
        const documentReference = doc(db, collection, id);
        const documentSnapshot = await getDoc(documentReference);
        if (documentSnapshot.exists()) {
            return { ...documentSnapshot.data(), id } as unknown as T;
        }
        return null;
    }
}
