
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import Navigation from '@/views/home/QuickGuides/Navigation.vue';
import Content from '@/views/home/QuickGuides/Content.vue';
import { useStore } from '@/plugins/store/quickGuidesStore';

export default defineComponent({
    components: { Navigation, Content },
    computed: {
        ...mapState(useStore, ['activeQuickGuide']),
    },
});
