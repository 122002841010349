
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';
import { Item } from '@/types';
import useItemPaths from '@/util/composables/useItemPaths';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

export default defineComponent({
    emits: ['navigating'],
    props: {
        algoliaItem: {
            type: Object as () => { objectID: string; content: string; title: string },
            required: true,
        },
    },
    computed: {
        ...mapState(useStore, ['items', 'sections']),
        item() {
            return this.items!.find((currentItem) => currentItem.id === this.algoliaItem.objectID) as Item;
        },
        path() {
            const { getPathForItem } = useItemPaths();
            return getPathForItem(this.item, true)
                .map((currentItemOrSection) => URIEncodeItem(currentItemOrSection))
                .join('/');
        },
    },
});
