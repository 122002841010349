import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col divide-y py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_AisSearchBox = _resolveComponent("AisSearchBox")!
  const _component_AlgoliaSearchItem = _resolveComponent("AlgoliaSearchItem")!
  const _component_AisHits = _resolveComponent("AisHits")!
  const _component_AisInstantSearch = _resolveComponent("AisInstantSearch")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.items)
      ? _withDirectives((_openBlock(), _createBlock(_component_AisInstantSearch, {
          key: 0,
          "search-client": _ctx.searchClient,
          "index-name": _ctx.indexName,
          class: "relative"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AisSearchBox, { placeholder: "Søg i hele håndbogen" }, {
              default: _withCtx(({ currentRefinement, refine }) => [
                _createVNode(_component_SearchInput, {
                  placeholder: "Søg i hele håndbogen",
                  class: "lg:w-80",
                  value: currentRefinement,
                  onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandDropdown = true)),
                  "onUpdate:value": (newValue) => refine(newValue),
                  onOnEscape: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expandDropdown = false)),
                  onOnDown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focusSearchItem(0)))
                }, null, 8, ["value", "onUpdate:value"])
              ]),
              _: 1
            }),
            _createElementVNode("div", {
              class: _normalizeClass([[{ 'max-h-64': _ctx.expandDropdown }, { 'max-h-0': !_ctx.expandDropdown }], "absolute z-20 top-full rounded-md right-0 left-0 bg-white overflow-y-auto shadow-md pl-2 pr-4 transition-max-h duration-200"])
            }, [
              _createVNode(_component_AisHits, null, {
                default: _withCtx(({ items }) => [
                  _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AlgoliaSearchItem, {
                        ref_for: true,
                        ref: `search_item_${index}`,
                        key: item.objectID,
                        "algolia-item": item,
                        onNavigating: _cache[3] || (_cache[3] = ($event: any) => (_ctx.expandDropdown = false)),
                        onKeydown: [
                          _withKeys(_withModifiers(($event: any) => (_ctx.focusSearchItem(index + 1)), ["prevent"]), ["down"]),
                          _withKeys(_withModifiers(($event: any) => (_ctx.focusSearchItem(index - 1)), ["prevent"]), ["up"])
                        ],
                        onFocus: ($event: any) => (_ctx.focusedItemIndex = index)
                      }, null, 8, ["algolia-item", "onKeydown", "onFocus"]))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ], 2)
          ]),
          _: 1
        }, 8, ["search-client", "index-name"])), [
          [_directive_click_outside, () => (_ctx.expandDropdown = false)]
        ])
      : _createCommentVNode("", true)
  ]))
}