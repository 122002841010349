
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore as useMainStore } from '@/plugins/store/mainStore';
import { useStore as useOverviewStore } from '@/plugins/store/overviewStore';
import SearchInput from '@/components/ui/SearchInput.vue';
import AlgoliaSearchItem from '@/views/home/Overview/navigation/AlgoliaSearchItem.vue';
import ClickOutside from '@/util/directives/ClickOutside';

export default defineComponent({
    components: { SearchInput, AlgoliaSearchItem },
    directives: { ClickOutside },
    data() {
        return {
            indexName: process.env.VUE_APP_ALGOLIA_INDEX_NAME,
            expandDropdown: false,
            focusedItemIndex: -1,
        };
    },
    computed: {
        ...mapState(useMainStore, ['searchClient']),
        ...mapState(useOverviewStore, ['items']),
    },
    methods: {
        focusSearchItem(index: number) {
            const ref = this.$refs[`search_item_${index}`] as any;
            if (ref && ref[0]) {
                const element = ref[0].$el;
                element.focus();
            }
        },
    },
});
