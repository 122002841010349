import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex h-full flex-col"
}
const _hoisted_2 = { class: "text-center pt-2" }
const _hoisted_3 = { class: "flex h-full flex-col my-auto space-y-4 text-center" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "mt-auto"
}
const _hoisted_6 = {
  class: "text-white block bg-blue-600 w-full py-2 rounded-md",
  href: "https://google.com",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-button": "",
    size: "w-100",
    padding: "px-10 py-6"
  }, {
    toggle: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "toggle", _normalizeProps(_guardReactiveProps(state)))
    ]),
    body: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "body", _normalizeProps(_guardReactiveProps(state)), () => [
        (_ctx.evaluationBox)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.evaluationBox.title), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "mt-4",
                  innerHTML: _ctx.evaluationBox.content
                }, null, 8, _hoisted_4),
                (_ctx.evaluationBox.buttonShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.evaluationBox.buttonText), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}