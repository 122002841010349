
import { defineComponent } from 'vue';

export default defineComponent({
    emits: ['update:value', 'focus', 'blur', 'submit', 'onEscape', 'onTab', 'onDown'],
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Search...',
        },
        skipVModel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uid: `input-${Math.random().toString(36).substring(6)}`,
            inputValue: this.$props.value,
        };
    },
    computed: {
        computedValue: {
            get(): string {
                return this.value;
            },
            set(newValue: string) {
                this.$emit('update:value', newValue);
            },
        },
    },
    watch: {
        inputValue(newValue: string) {
            if (this.skipVModel || this.computedValue !== newValue) {
                this.computedValue = newValue;
            }
        },
        value(newValue: string) {
            if (this.inputValue !== newValue) {
                this.inputValue = newValue;
            }
        },
    },
    methods: {
        onEscapeClicked() {
            (this.$refs.input as HTMLInputElement).blur();
            this.$emit('onEscape');
        },
    },
});
