import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white pt-12 pb-8 px-8 rounded-md shadow-md" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "space-y-8 flex flex-col" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    (_ctx.activePage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.activePage.title), 1),
            _createElementVNode("div", {
              class: "dynamic-content",
              innerHTML: _ctx.activePage.content
            }, null, 8, _hoisted_4)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}