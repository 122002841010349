
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';
import { Item, Section } from '@/types';
import format from '@/plugins/dates';
import OverviewNavigationItem from '@/views/home/Overview/navigation/OverviewNavigationItem.vue';
import { URIEncodeItem } from '@/util/helpers/encodeItem';
import useFootnotes from '@/util/composables/useFootnotes';

export default defineComponent({
    components: { OverviewNavigationItem },
    setup() {
        const { onUnmounted } = useFootnotes();
        return { onUnmounted };
    },
    computed: {
        ...mapState(useStore, ['items', 'activeItem', 'activeSection', 'itemsSortedByParent', 'sections']),
        readAlso(): Item[] {
            const result = this.activeItem?.readAlso
                ?.map((currentReadAlso) => {
                    return this.items?.find((currentItem) => currentItem.id === currentReadAlso.id);
                })
                .filter((currentReadAlsoItem) => Boolean(currentReadAlsoItem));
            return result as Item[];
        },
        childItems(): Item[] {
            return this.itemsSortedByParent.items[this.activeItem!.id];
        },
        formattedDate(): string {
            return this.activeItem ? format(new Date(this.activeItem.updatedAt.seconds * 1000), 'Pp') : '';
        },
    },
    methods: {
        getURIEncodedSectionTitle(item: Item) {
            if (item.parentSection && item.parentSection.length) {
                return URIEncodeItem(this.sections!.find((currentSection) => currentSection.id === item.parentSection![0].id)!);
            }
            if (item.parentItem && item.parentItem.length) {
                const parentItem = this.items!.find((currentItem) => currentItem.id);
                if (parentItem && parentItem.parentSection && parentItem.parentSection.length) {
                    return URIEncodeItem(this.sections!.find((currentSection) => currentSection.id === parentItem.parentSection![0].id)!);
                }
            }
            return '';
        },
    },
});
