
import { defineComponent, computed } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';

export default defineComponent({
    computed: {
        ...mapState(useStore, ['activeSection']),
        navigationText(): string {
            return this.activeSection ? 'Gå til oversigt' : 'Oversigt';
        },
    },
});
