
import { defineComponent } from 'vue';
import Modal from '@/components/ui/Modal.vue';
import { EvaluationBox } from '@/types';

export default defineComponent({
    components: { Modal },
    props: {
        evaluationBox: {
            type: Object as () => EvaluationBox,
            default: null,
        },
    },
});
