
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';
import QuestionnaireModal from '@/views/home/Overview/content/QuestionnaireModal.vue';

export default defineComponent({
    components: { QuestionnaireModal },
    data() {
        return {
            CONTENT_EVALUATION_BOX: 'content',
        };
    },
    computed: {
        ...mapState(useStore, ['activeItem', 'evaluationBox']),
    },
});
