
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';
import OverviewNavigationItem from '@/views/home/Overview/navigation/OverviewNavigationItem.vue';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

export default defineComponent({
    components: { OverviewNavigationItem },
    computed: {
        ...mapState(useStore, ['sections', 'activeSection']),
    },
    methods: {
        getURIEncodedItemOrSectionTitle: URIEncodeItem,
    },
});
