import { defineStore } from 'pinia';
import { Page } from '@/types';
import FirebaseClient, { Collection } from './FirebaseClient';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

const useStore = defineStore('about', {
    state: () => {
        return {
            pages: null as null | Page[],
            activePage: null as null | Page,
        };
    },
    actions: {
        async initSite() {
            this.getPages();
        },
        async getPages() {
            if (this.pages === null) {
                const pages = await FirebaseClient.getCollection<Page>(Collection.Pages);
                this.pages = pages
                    .filter((currentPage) => currentPage.type === 'default')
                    .sort((a, b) => {
                        return a.sortOrder - b.sortOrder;
                    });
            }
        },
        async setActivePageForRoute(pathParams: { pageTitle: string }) {
            if (this.pages === null) {
                await new Promise((resolve) => {
                    const unsubscribe = this.$subscribe((mutation, state) => {
                        if (state.pages !== null) {
                            unsubscribe();
                            resolve(true);
                        }
                    });
                });
            }
            const resolvedPage = this.pages!.find((currentPage) => URIEncodeItem(currentPage) === URIEncodeItem({ title: pathParams.pageTitle }));
            if (resolvedPage) {
                this.activePage = resolvedPage;
            }
        },
    },
});

export { useStore };
