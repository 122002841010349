import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-1 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/oversigt/${_ctx.path}`,
    class: _normalizeClass([[{ 'shadow-blue-600 shadow-inner-equal-sm': _ctx.activeItem && _ctx.item.id === _ctx.activeItem.id }, { 'shadow-md': _ctx.item.id !== _ctx.activeItem?.id }], "bg-white border border-opacity-10 border-black rounded-md py-4 px-4 w-full flex"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.item.title), 1)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}