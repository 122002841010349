
import { defineComponent } from 'vue';
import { mapState, mapWritableState } from 'pinia';
import { useStore } from '@/plugins/store/quickGuidesStore';
import { QuickGuide } from '@/types';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

export default defineComponent({
    computed: {
        ...mapState(useStore, ['quickGuides']),
        ...mapWritableState(useStore, ['activeQuickGuide']),
    },
    methods: {
        getURIEncodedQuickGuideTitle(quickGuide: QuickGuide) {
            return URIEncodeItem(quickGuide);
        },
    },
    watch: {
        quickGuides: {
            immediate: true,
            deep: true,
            handler(newValue: QuickGuide[]) {
                if (newValue && !this.activeQuickGuide && window.innerWidth > 1024) {
                    this.$router.replace({ name: 'QuickGuides', params: { quickGuideTitle: this.getURIEncodedQuickGuideTitle(newValue[0]) } });
                }
            },
        },
        activeQuickGuide: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue === null && this.quickGuides && window.innerWidth > 1024) {
                    this.activeQuickGuide = this.quickGuides[0];
                }
            },
        },
    },
});
