
import { defineComponent, ref } from 'vue';

export default defineComponent({
    data() {
        return {
            expandMenu: false,
        };
    },
    computed: {
        routes() {
            return [
                {
                    title: 'Oversigt',
                    routeName: 'Overview',
                    isSelected: this.$route.fullPath.includes('/oversigt'),
                },
                {
                    title: 'Quick Guides',
                    routeName: 'QuickGuides',
                    isSelected: this.$route.fullPath.includes('/quick-guides'),
                },
                {
                    title: 'Om',
                    routeName: 'About',
                    isSelected: this.$route.fullPath.includes('/om'),
                },
            ];
        },
    },
    methods: {
        onExpandMenuClicked() {
            setTimeout(() => {
                (this as any).expandMenu = !(this as any).expandMenu;
            }, 100);
        },
    },
});
