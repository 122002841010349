import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FootnoteModal = _resolveComponent("FootnoteModal")!
  const _component_HomeLayout = _resolveComponent("HomeLayout")!

  return (_openBlock(), _createBlock(_component_HomeLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      _createVNode(_component_FootnoteModal, null, {
        toggle: _withCtx(({ open }) => [
          _createElementVNode("input", {
            id: "footnote-modal-input",
            class: "hidden",
            type: "hidden",
            onClick: open
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}