
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';
import { Item, Section } from '@/types';
import OverviewNavigationItem from '@/views/home/Overview/navigation/OverviewNavigationItem.vue';
import { URIEncodeItem } from '@/util/helpers/encodeItem';
import ItemNavigation from '@/views/home/Overview/navigation/ItemNavigation.vue';
import OverviewNavigation from '@/views/home/Overview/navigation/OverviewNavigation.vue';

export default defineComponent({
    components: { OverviewNavigationItem, ItemNavigation, OverviewNavigation },
    computed: {
        ...mapState(useStore, ['activeSection']),
    },
    methods: {
        getURIEncodedItemOrSectionTitle: URIEncodeItem,
    },
});
