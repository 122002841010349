
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/overviewStore';
import DefaultContent from '@/views/home/Overview/content/DefaultContent.vue';
import ItemContent from '@/views/home/Overview/content/ItemContent.vue';
import SectionContent from '@/views/home/Overview/content/SectionContent.vue';
import { Item, Section } from '@/types';
import useItemPaths from '@/util/composables/useItemPaths';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

export default defineComponent({
    components: { DefaultContent, ItemContent, SectionContent },
    computed: {
        ...mapState(useStore, ['activeItem', 'activeSection']),
        backToSectionNavigation(): { text: string; route: string } {
            return {
                text: this.currentPath[0].title,
                route: `/oversigt/${URIEncodeItem(this.currentPath[0])}`,
            };
        },
        currentPath(): (Section | Item)[] {
            if (this.activeItem) {
                const { getPathForItem } = useItemPaths();
                return getPathForItem(this.activeItem, true);
            }
            if (this.activeSection) {
                return [this.activeSection];
            }

            return [];
        },
    },
});
