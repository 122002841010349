
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import Navigation from '@/views/home/Overview/navigation/Navigation.vue';
import Content from '@/views/home/Overview/content/Content.vue';
import AlgoliaSearch from '@/views/home/Overview/navigation/AlgoliaSearch.vue';
import OverviewBreadcrump from '@/views/home/Overview/navigation/OverviewBreadcrump.vue';
import { useStore } from '@/plugins/store/overviewStore';

export default defineComponent({
    components: { Navigation, Content, AlgoliaSearch, OverviewBreadcrump },
    computed: {
        ...mapState(useStore, ['activeItem']),
    },
});
