import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "mx-auto container my-9",
  style: {"min-height":"calc(100vh - 20rem)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeNavbar = _resolveComponent("HomeNavbar")!
  const _component_HomeFooter = _resolveComponent("HomeFooter")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HomeNavbar),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_HomeFooter)
  ]))
}