import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-button": "",
    size: "w-100",
    padding: "px-10 py-6"
  }, {
    toggle: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "toggle", _normalizeProps(_guardReactiveProps(state)))
    ]),
    body: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "body", _normalizeProps(_guardReactiveProps(state)), () => [
        (_ctx.activeFootnote)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "text-center",
              innerHTML: _ctx.activeFootnoteContent
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}