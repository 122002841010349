import { defineStore } from 'pinia';
import { Hierarchy, EvaluationBox, HierarchyItem, Item, Section } from '@/types';
import FirebaseClient, { Collection } from './FirebaseClient';
import { waitForItems } from '@/util/helpers/waitForItems';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

const useStore = defineStore('overview', {
    state: () => {
        return {
            items: null as null | Item[],
            sections: null as null | Section[],
            activeSection: null as null | Section,
            activeItem: null as null | Item,
            evaluationBox: null as null | EvaluationBox,
        };
    },
    getters: {
        itemsAndSectionsDirectAccess(): { items: { [key: string]: Item }; sections: { [key: string]: Section } } {
            return {
                items: (this.items || []).reduce((accum, current) => {
                    accum[current.id] = current;
                    return accum;
                }, {} as { [key: string]: Item }),
                sections: (this.sections || []).reduce((accum, current) => {
                    accum[current.id] = current;
                    return accum;
                }, {} as { [key: string]: Section }),
            };
        },
        sectionAndItemsHierarchy(): Hierarchy | undefined {
            const getItemsWithChildren = (item: Item, level: number): HierarchyItem[] => {
                return (
                    this.itemsSortedByParent.items[item.id]?.map((currentItem) => {
                        return { ...currentItem, items: getItemsWithChildren(currentItem, level + 1), level };
                    }) || []
                );
            };

            const result = this.sections?.map((currentSection) => {
                return {
                    ...currentSection,
                    items: this.itemsSortedByParent.sections[currentSection.id].map((currentItem) => {
                        return { ...currentItem, level: 0, items: getItemsWithChildren(currentItem, 1) };
                    }),
                };
            });

            return result;
        },
        itemsSortedByParent() {
            const itemsSortedByParent: { sections: { [key: string]: Item[] }; items: { [key: string]: Item[] } } = {
                sections: {},
                items: {},
            };
            if (this.items === null || this.sections === null) {
                return itemsSortedByParent;
            }
            this.items.forEach((currentItem) => {
                if (currentItem.parentSection?.length) {
                    currentItem.parentSection.forEach((currentParentSection) => {
                        itemsSortedByParent.sections[currentParentSection.id] = itemsSortedByParent.sections[currentParentSection.id] || [];
                        itemsSortedByParent.sections[currentParentSection.id].push(currentItem);
                    });
                }
                currentItem.parentItem.forEach((currentParentItem) => {
                    itemsSortedByParent.items[currentParentItem.id] = itemsSortedByParent.items[currentParentItem.id] || [];
                    itemsSortedByParent.items[currentParentItem.id].push(currentItem);
                });
            });
            return itemsSortedByParent;
        },
        activeItemChildren(): Item[] {
            if (this.items === null || this.activeItem === null) {
                return [];
            }
            return this.items.filter((currentItem) => {
                return (
                    Boolean(currentItem.parentItem?.length) &&
                    currentItem.parentItem?.find((currentParentItem) => {
                        return currentParentItem.id === this.activeItem!.id;
                    })
                );
            });
        },
    },
    actions: {
        initSite() {
            this.getItems();
            this.getSections();
            this.getEvaluationBox();
        },
        async getEvaluationBox() {
            if (this.evaluationBox === null) {
                const evaluationBox = await FirebaseClient.getDocumentData<EvaluationBox | null>(Collection.Meta, 'evaluation');
                if (evaluationBox) {
                    this.evaluationBox = evaluationBox;
                }
            }
        },
        async getItems() {
            if (this.items === null) {
                const items = await FirebaseClient.getCollection<Item>(Collection.Items);
                this.items = items.sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                });
            }
        },
        async getSections() {
            if (this.sections === null) {
                const sections = await FirebaseClient.getCollection<Section>(Collection.Sections);
                this.sections = sections.sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                });
            }
        },
        async setActiveSectionAndSiteForRoute(params: { path: string }) {
            await waitForItems();
            const pathConstituents = params.path.split('/').filter((currentPath) => currentPath.length);
            const resolvedActiveSection = this.sections!.find((currentSection) => {
                return URIEncodeItem(currentSection) === URIEncodeItem({ title: pathConstituents[0] });
            });
            if (resolvedActiveSection) {
                this.activeSection = resolvedActiveSection;
            } else {
                this.activeSection = null;
            }
            if (resolvedActiveSection && pathConstituents.length > 1) {
                let currentPathItem = this.items?.find((currentItem) => {
                    return (
                        URIEncodeItem(currentItem) === URIEncodeItem({ title: pathConstituents[1] }) &&
                        currentItem.parentSection &&
                        currentItem.parentSection.length &&
                        currentItem.parentSection[0].id === resolvedActiveSection.id
                    );
                });
                for (let i = 2; i < pathConstituents.length; i++) {
                    currentPathItem = this.items?.find((currentItem) => {
                        return URIEncodeItem(currentItem) === URIEncodeItem({ title: pathConstituents[i] }) && currentItem.parentItem?.length && currentItem.parentItem[0].id === currentPathItem?.id;
                    });
                }
                if (currentPathItem) {
                    this.activeItem = currentPathItem;
                } else {
                    this.activeItem = null;
                }
            } else if (pathConstituents.length === 1) {
                this.activeItem = null;
            }
        },
    },
});

export { useStore };
