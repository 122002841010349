
import { defineComponent } from 'vue';
import { mapWritableState } from 'pinia';
import { useStore } from '@/plugins/store/aboutStore';
import useFootnotes from '@/util/composables/useFootnotes';

export default defineComponent({
    setup() {
        const { onUnmounted } = useFootnotes();
        return { onUnmounted };
    },
    computed: {
        ...mapWritableState(useStore, ['activePage']),
    },
});
