import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-auto" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "my-auto" }
const _hoisted_4 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'max-h-100 delay-500': _ctx.activeSection, 'max-h-600': !_ctx.activeSection }, "space-y-4 transition-max-h duration-500 overflow-y-hidden"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: section.id,
        to: `/oversigt/${_ctx.getURIEncodedItemOrSectionTitle(section)}`,
        class: "bg-white border border-opacity-10 border-black rounded-md py-4 px-4 w-full text-left flex space-x-2"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              class: "h-14 min-w-14 rounded-full",
              src: section.imageUrl
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", null, _toDisplayString(section.title), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(section.subtitle), 1)
          ])
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ], 2))
}