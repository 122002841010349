
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import Navigation from '@/views/home/About/Navigation.vue';
import Content from '@/views/home/About/Content.vue';
import { useStore } from '@/plugins/store/aboutStore';

export default defineComponent({
    components: { Navigation, Content },
    computed: {
        ...mapState(useStore, ['activePage']),
    },
});
