
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useStore } from '@/plugins/store/aboutStore';
import { Page } from '@/types';
import QuestionnaireModal from '@/views/home/Overview/content/QuestionnaireModal.vue';
import { URIEncodeItem } from '@/util/helpers/encodeItem';

export default defineComponent({
    components: { QuestionnaireModal },
    computed: {
        ...mapState(useStore, ['pages']),
    },
    methods: {
        getURIEncodedPageTitle: URIEncodeItem,
    },
});
