import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/oversigt/${_ctx.path}`,
    class: "py-2 font-medium text-opacity-80 outline-none focus:ring",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('navigating')))
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.item.title), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}