import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "my-auto" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "my-auto" }
const _hoisted_5 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: page.id,
        to: {
                name: 'About',
                params: { pageTitle: _ctx.getURIEncodedPageTitle(page) },
            },
        class: _normalizeClass([[{ 'shadow-blue-600 shadow-inner-equal-sm': page.id === _ctx.activePage?.id }, { 'shadow-md': page.id !== _ctx.activePage?.id }], "bg-white border border-opacity-10 border-black rounded-md py-4 px-4 w-full text-left flex space-x-2"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              class: "h-14 min-w-14 rounded-full",
              src: page.imageUrl
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", null, _toDisplayString(page.title), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(page.subtitle), 1)
          ])
        ]),
        _: 2
      }, 1032, ["to", "class"]))
    }), 128))
  ]))
}