import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hidden lg:flex lg:flex-row space-x-2" }
const _hoisted_2 = { class: "lg:my-auto font-medium whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/oversigt",
        class: _normalizeClass(["flex", { 'space-x-3': _ctx.activeSection }])
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            class: _normalizeClass([{ 'max-w-0': !_ctx.activeSection }, "h-6 pb-1 transition-all"]),
            src: "/images/ic_back.png"
          }, null, 2),
          _createElementVNode("span", null, _toDisplayString(_ctx.navigationText), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}